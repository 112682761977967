<template>
  <div class="d-flex flex-column fill-height">
    <v-flex v-if="loading" class="d-flex justify-center align-center py-16">
      <v-progress-circular
        :size="100"
        color="accent"
        indeterminate
      />
    </v-flex>

    <template v-else>
      <PageHeader>
        <div class="d-flex align-center overflow-hidden">
          <h1 class="d-flex align-center text-title-1 overflow-hidden">
            <v-btn icon class="mr-1" @click="goToProject" color="gray-60" exact>
              <IconArrowLeft width="16" style="margin-top: 2px;"/>
            </v-btn>
            <v-tooltip top color="rgba(47, 49, 53, 0.7)">
              <template #activator="{attrs, on}">
                <span class="text-truncate" v-bind="attrs" v-on="on">{{ getProject.title }} </span>
              </template>
              <div class="text-captions-1">
                {{ getProject.title }}
              </div>
            </v-tooltip>
          </h1>
          <IconDoubleChevronRight width="15" class="mx-4 accent-70--text flex-shrink-0"/>
          <span class="text-title-1 accent--text">Workflow edit</span>
        </div>
      </PageHeader>

      <v-container class="relative">
        <div class="py-10 gray-0">
          <div class="d-flex align-center">
            <UiBtn
              width="126"
              color="accent"
              v-if="canEdit"
              :disabled="(!scheme?.main?.length) || (!scheme?.additional?.length && showAdditionalWorkflow) || loading"
              @click="saveAndClose"
              class="ml-auto"
            >
              Save Changes
            </UiBtn>
          </div>

          <v-divider class="mt-4 mb-5"/>

          <div class="workflow-tables-wrap d-flex space-x-4" v-if="scheme">
            <div class="workflow-tables-col d-flex flex-column flex-shrink-0 fill-width">
              <div
                class="d-flex align-center justify-space-between mb-6 text-body font-weight-semi-bold gray-100--text">
                Process Library
                <UiBtn disabled text plain width="auto" height="auto" color="accent" class="px-0 border-b-s rounded-0">
                  + Add new module
                </UiBtn>
              </div>
              <div class="workflow-table fill-width py-6 px-3 rounded">
                <draggable
                  :list="modulesDictionary"
                  :group="{ name: 'modules', pull: 'clone', put: canPut}"
                  :clone="cloneDictionaryModule"
                  handle=".drag-handle"
                  :disabled="!canEdit"
                  @add="dropModuleToDictionary"
                  :move="onMove"
                  class="fill-height fill-width"
                  data-list="modulesDictionary"
                >
                  <div v-for="(module, idx) in modulesDictionary" :key="idx"
                       class="workflow-table-row-item bordered-item d-flex align-center rounded pl-2 pr-3">
                    <div :class="[
                  'd-flex mr-2',
                  (scheme.main.length >= maxSchemeModules * 2 || (!canEdit)) ? 'gray-10--text' : 'drag-handle gray-30--text cursor-pointer'
                  ]">
                      <IconDotsGrid width="15" class="accent-80--text"/>
                    </div>

                    <div class="mr-4 text-body font-weight-light gray-100--text text-overflow-ellipsis text-no-wrap overflow-hidden gray-60--text">
                      {{ module.title }}
                    </div>

                    <UiBtn text icon width="26" height="26" color="accent" class="ml-auto"
                           :disabled="(scheme.main.length >= maxSchemeModules || (!canEdit))"
                           @click="moduleToProcess(module)">
                      <IconPlus width="16"/>
                    </UiBtn>
                  </div>
                </draggable>
              </div>
            </div>

            <div class="workflow-tables-col d-flex flex-column fill-width">

              <div
                class="d-flex align-center justify-space-between mb-4 text-body font-weight-semi-bold gray-100--text">
                Process Flow
                <div class="d-flex align-center">
                  <UiSwitch
                    class="mr-2"
                    v-model="showAdditionalWorkflow"
                    inset
                    color="accent-80"
                    hide-details
                    :true-value="true"
                    :false-value="false"
                    :disabled="!canEdit"
                    @change="onShowAdditionalWorkflowChange"
                  >
                    <template #label>
                      <span class="text-body font-weight-light">Additional Workflow</span>
                      <v-tooltip top :nudge-top="4" color="gray-80" max-width="292" content-class="closed-workflow-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on" class="d-flex ml-1">
                            <IconInfoCircle width="16" class="accent-80--text"/>
                          </div>
                        </template>
                        <span class="gray-0--text">
                      Enables an extra workflow section in project. This can be used to create independent steps in your workflow which does not depend on a closed workflow progress
                  </span>
                      </v-tooltip>
                    </template>
                  </UiSwitch>
                  <UiSwitch
                    class="mr-2"
                    v-model="scheme.settings.include_weekend_main"
                    inset
                    color="accent-80"
                    hide-details
                    :true-value="true"
                    :false-value="false"
                    :disabled="!canEdit"
                    @change="scheme.settings.include_weekend_additional = $event"
                  >
                    <template #label>
                        <span class="text-body font-weight-light">Include weekend in deadline</span>
                      <v-tooltip top :nudge-top="4" color="gray-80" max-width="292" content-class="closed-workflow-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on" class="d-flex ml-1">
                            <IconInfoCircle width="16" class="accent-80--text"/>
                          </div>
                        </template>
                        <span class="gray-0--text">
                          When toggle is on a deadline will be able to end on a weekend.
                        </span>
                      </v-tooltip>
                    </template>
                  </UiSwitch>
                </div>
              </div>
              <div class="workflow-table d-flex flex-column fill-height fill-width py-6 rounded">
                <div class="d-flex flex-column px-3">
                  <div class="text-body gray-100--text mb-3">Main Workflow</div>
                  <div class="ml-1 d-flex mb-3">
                    <UiSwitch
                      class="workflow-approved"
                      :disabled="!canEdit || !canChangeOpenFlowMain"
                      v-model="scheme.settings.open_workflow_main"
                      inset
                      color="#6A47E9"
                      hide-details
                    >
                      <template #label>
                        <div class="d-flex text-body font-weight-light gray-100--text">
                          Open Workflow

                          <v-tooltip top :nudge-top="4" color="gray-80" max-width="292" content-class="closed-workflow-tooltip">
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on" class="d-flex ml-1">
                                <IconInfoCircle width="16" class="gray-60--text"/>
                              </div>
                            </template>
                            <span class="gray-0--text">
                      By default, a project won’t allow users to jump to the following process until the current one is completed. An open workflow does not have a specific order, and all the processes can be accessed from the beginning.
                    </span>
                          </v-tooltip>
                        </div>
                      </template>
                    </UiSwitch>
                  </div>
                  <div v-if="scheme?.main?.length" class="workflow-table-head gray-100--text d-flex mb-4 d-flex justify-space-between">
                    <div class="header-col-1 text-captions-1">Module name</div>
                    <div class="d-flex justify-end">
                      <div class="header-col-2 fill-width text-captions-1 ml-5">Status</div>
                      <div class="header-col-3 fill-width text-captions-1 ml-5 duration">{{ scheme.settings.include_weekend_main ? 'Calendar' : 'Work' }} Days</div>
                      <div class="header-col-4 flex-shrink-0 text-captions-1 ml-3">Actions</div>
                    </div>
                  </div>


                  <draggable
                    :list="scheme.main"
                    group="modules"
                    handle=".drag-handle"
                    @add="dropModuleToProcess($event, $config.project.workflowTypesSlugs.main)"
                    :disabled="!canEdit"
                    @start="checkMove($event, $config.project.workflowTypesSlugs.main)"
                    @end="canPut = true"
                    class="relative fill-height fill-width"
                    data-list="main"
                    :move="onMove"
                  >
                    <div v-for="(module, idx) in scheme.main" :key="idx" :id="module.module_id" class="d-flex space-x-2">
                      <div
                        class="workflow-table-row-item grid-item gray-100--text bordered-item d-flex align-center fill-width rounded pl-2 pr-3">
                        <div class="first-col d-flex align-center mr-2">
                          <div
                            :class="['d-flex mr-2', (canRemove(module) && canEdit) ? 'drag-handle gray-30--text cursor-pointer' : 'gray-10--text']">
                            <IconDotsGrid width="15" class="accent-80--text"/>
                          </div>
                          <div class="text-body font-weight-semi-bold">{{ idx + 1 }}</div>
                        </div>

                        <div class="d-flex fill-width align-center flex-auto">
                          <span class="text-truncate text-body font-weight-light">{{ module.title }}</span>
                        </div>

                        <div class="approved-col mx-4 d-flex space-x-2 text-body font-weight-light">
                          {{module.approve ? 'Auto-approved' : 'Requires approval'}}
                        </div>
                        <div class="ttl-col text-body font-weight-light">{{module.ttl}} {{ module.ttl == 1 ? 'day' : 'days' }}</div>
                        <div class="d-flex align-center ml-3">
                          <UiBtn text icon plain width="auto" height="auto" v-if="canEdit" class="mr-1"
                                 @click="$store.dispatch('openModal', {
                        modalName: 'editWorkflowModuleModal',
                        data: {
                          module: {
                            ...module,
                            moduleTypeSlug: $config.project.workflowTypesSlugs.main,
                            moduleIdx: idx
                          },
                          include_weekend: scheme.settings.include_weekend_main
                        }
                       })">
                            <IconPen width="14" class="gray-60--text"/>
                          </UiBtn>
                          <UiBtn text icon plain width="30" height="30" color="gray-60"
                                 :disabled="!canRemove(module) || (!canEdit)"
                                 @click="moduleToDictionary(module, idx, $config.project.workflowTypesSlugs.main)">
                            <IconDelete width="16"/>
                          </UiBtn>
                        </div>
                      </div>
                    </div>

                    <DragDropEmptyState v-if="!scheme.main.length" />
                  </draggable>
                </div>
                <template v-if="showAdditionalWorkflow">
                  <v-divider class="my-3"/>
                  <div class="d-flex flex-column px-3">
                    <div class="text-body gray-100--text mb-3">Additional Workflow</div>
                    <div class="ml-1 d-flex mb-3">
                      <UiSwitch
                        class="workflow-approved"
                        :disabled="!canEdit || !canChangeOpenFlowAdditional"
                        v-model="scheme.settings.open_workflow_additional"
                        inset
                        color="#6A47E9"
                        hide-details
                      >
                        <template #label>
                          <div class="d-flex text-body font-weight-light gray-100--text">
                            Open Workflow

                            <v-tooltip top :nudge-top="4" color="gray-80" max-width="292" content-class="closed-workflow-tooltip">
                              <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on" class="d-flex ml-1">
                                  <IconInfoCircle width="16" class="gray-60--text"/>
                                </div>
                              </template>
                              <span class="gray-0--text">
                        By default, a project won’t allow users to jump to the following process until the current one is completed. An open workflow does not have a specific order, and all the processes can be accessed from the beginning.
                      </span>
                            </v-tooltip>
                          </div>
                        </template>
                      </UiSwitch>
                    </div>
                    <div v-if="scheme?.additional?.length" class="workflow-table-head gray-100--text d-flex justify-space-between mb-4">
                      <div class="header-col-1 text-captions-1">Module name</div>
                      <div class="d-flex justify-end">
                        <div class="header-col-2 fill-width text-captions-1 ml-5">Status</div>
                        <div class="header-col-3 fill-width text-captions-1 ml-5">{{ scheme.settings.include_weekend_additional ? 'Calendar' : 'Work' }} Days</div>
                        <div class="header-col-4 flex-shrink-0 text-captions-1 ml-3">Actions</div>
                      </div>
                    </div>
                    <draggable
                      :list="scheme.additional"
                      group="modules"
                      handle=".drag-handle"
                      @add="dropModuleToProcess($event, $config.project.workflowTypesSlugs.additional)"
                      :disabled="!canEdit"
                      @start="checkMove($event, $config.project.workflowTypesSlugs.additional)"
                      @end="canPut = true"
                      class="additional-drag relative fill-height fill-width"
                      data-list="additional"
                      :move="onMove"
                    >
                      <div v-for="(module, idx) in scheme.additional" :key="idx" class="d-flex space-x-2">
                        <div
                          class="workflow-table-row-item grid-item bordered-item gray-100--text d-flex align-center fill-width rounded pl-2 pr-3">
                          <div class="first-col d-flex align-center mr-2">
                            <div
                              :class="['d-flex mr-2', (canRemove(module) && canEdit) ? 'drag-handle gray-30--text cursor-pointer' : 'gray-10--text']">
                              <IconDotsGrid width="15" class="accent-80--text"/>
                            </div>
                            <div class="text-body font-weight-semi-bold">{{ idx + 1 }}</div>
                          </div>
                          <div class="d-flex fill-width align-center flex-auto">
                            <span class="text-truncate text-body font-weight-light">{{ module.title }}</span>
                          </div>

                          <div class="mx-4 approved-col d-flex space-x-2 text-body font-weight-light">
                            {{module.approve ? 'Auto-approved' : 'Requires approval'}}
                          </div>
                          <div class="ttl-col text-body font-weight-light">{{module.ttl}} {{ module.ttl == 1 ? 'day' : 'days' }}</div>
                          <div class="d-flex align-center ml-3">
                            <UiBtn text icon plain width="auto" height="auto" v-if="canEdit" class="mr-1"
                                   @click="$store.dispatch('openModal', {
                              modalName: 'editWorkflowModuleModal',
                              data: {
                                module: {
                                  ...module,
                                  moduleTypeSlug: $config.project.workflowTypesSlugs.additional,
                                  moduleIdx: idx
                                },
                                include_weekend: scheme.settings.include_weekend_additional
                              }
                           })">
                              <IconPen width="14" class="gray-60--text"/>
                            </UiBtn>
                            <UiBtn text icon plain width="30" height="30" color="gray-60"
                                   :disabled="!canRemove(module) || (!canEdit)"
                                   @click="moduleToDictionary(module, idx, $config.project.workflowTypesSlugs.additional)">
                              <IconDelete width="16"/>
                            </UiBtn>
                          </div>
                        </div>
                      </div>

                      <DragDropEmptyState v-if="!scheme.additional.length" />
                    </draggable>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </v-container>

    </template>
    <EditWorkflowModuleModal v-if="getModal('editWorkflowModuleModal').isOpen" @change="onModuleChange" />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Draggable from "vuedraggable";
import UiBtn from "@/components/UI/UiBtn";

import UiSwitch from "@/components/UI/UiSwitch.vue";
import PageHeader from "@/components/layout/PageHeader.vue";
import EditWorkflowModuleModal from "@/components/modals/EditWorkflowModuleModal.vue";
import DragDropEmptyState from "@/views/spaces/edit/DragDropEmptyState.vue"

export default {
  name: 'ProjectEditWorkflowPage',
  components: {
    PageHeader,
    UiSwitch,
    IconInfoCircle: () => import('@/components/icons/IconInfoCircle'),
    IconDotsGrid: () => import('@/components/icons/IconDotsGrid'),
    IconPlus: () => import('@/components/icons/IconPlus'),
    IconArrowLeft: () => import('@/components/icons/IconArrowLeft'),
    IconDoubleChevronRight: () => import('@/components/icons/IconDoubleChevronRight'),
    IconPen: () => import("@/components/icons/IconPen"),
    IconDelete: () => import("@/components/icons/IconDelete"),

    Draggable,
    UiBtn,
    EditWorkflowModuleModal,
    DragDropEmptyState,
  },
  data() {
    return {
      loading: true,
      defaultModulesDictionary: [],
      modulesDictionary: null,
      maxSchemeModules: 20,
      schemaList: [],
      canPut: true,
      scheme: {
        modules: [],
      },
      moduleTtlOptions: {
        decimal: "",
        thousands: "",
        suffix: "",
        precision: 0,
        masked: false
      },
      showAdditionalWorkflow: false
    }
  },
  computed: {
    ...mapGetters([
      'getModal',
      'getActiveSpace',
      'getSpaces',
      'getProject'
    ]),
    canEdit() {
      return this.getProject && this.getProject.can_edit_workflow_scheme
    },
    canChangeOpenFlowMain() {
      return this.getProject && (!this.getProject?.scheme?.settings?.open_workflow_main || this.getProject?.scheme?.settings?.open_workflow_main && this.getProject?.modules?.filter(module => {
        const isMoodboard = module.type === this.$config.project.moduleTypes.moodBoard
        return !isMoodboard && (module.status !== this.$config.project.moduleStatus.finished && module.total_files) && module.workflow_type === this.$config.project.workflowTypes.main
      }).length <= 1)
    },
    canChangeOpenFlowAdditional() {
      return this.getProject && (!this.getProject?.scheme?.settings?.open_workflow_additional || this.getProject?.scheme?.settings?.open_workflow_additional && this.getProject?.modules?.filter(module => {
        const isMoodboard = module.type === this.$config.project.moduleTypes.moodBoard
        return !isMoodboard && (module.status !== this.$config.project.moduleStatus.finished && module.total_files) && module.workflow_type === this.$config.project.workflowTypes.additional
      }).length <= 1)
    },
    canRemove() {
      return (module) => {
        return !module.items_count
      }
    }
  },
  async created() {
    await this.getAllData()

    this.loading = false;
  },
  methods: {
    checkMove(evt, moduleWorkFlowType) {
      const module = this.scheme[moduleWorkFlowType][evt.oldIndex]
      this.canPut = this.canRemove(module)
    },
    goToProject() {
      this.$router.push({
        name: 'ProjectDesign',
        params: {project_id: this.$route.params.project_id},
        query: {module: this.$route.params.module_id}
      })
    },
    async getAllData() {
      try {

        const [projectResponse, workflowSchemeResponse, schemasListRes, modulesListResponse] = await Promise.all([
          this.$api.project.get(this.$route.params.project_id),
          this.$api.project.getProjectWorkflowScheme(this.$route.params.project_id),
          this.$api.workflowScheme.list(),
          this.$api.module.list()
        ])

        this.defaultModulesDictionary = modulesListResponse.sort((a, b) => a.id > b.id ? 1 : -1).map(module => ({
          ...module,
          ttl: 1,
          module_id: module.id,
          showRenameInput: false,
          approve: false
        }));

        this.modulesDictionary = [...this.defaultModulesDictionary];
        await this.$store.dispatch('setProject', projectResponse.data);
        this.schemaList = schemasListRes
        this.setWorkflowScheme(workflowSchemeResponse.data);
        this.setShowAdditionalWorkflowValue();
      } catch (err) {
        console.log('err - ', err)
      }
    },
    setWorkflowScheme(scheme) {
      this.scheme = scheme;
      if (!this.scheme) {
        return
      }
      this.scheme.main = this.scheme.main.map(module => {
        return {
          ...module,
          newTitle: module.title,
          showRenameInput: false,
        }
      }).sort((a, b) => a.sort_order > b.sort_order ? 1 : -1);

      this.scheme.additional = this.scheme.additional.map(module => {
        return {
          ...module,
          newTitle: module.title,
          showRenameInput: false,
        }
      }).sort((a, b) => a.sort_order > b.sort_order ? 1 : -1);

      if (this.scheme.main.find(module => module.type === this.$config.project.moduleTypes.moodBoard) || this.scheme.additional.find(module => module.type === this.$config.project.moduleTypes.moodBoard)) {
        this.modulesDictionary = this.defaultModulesDictionary.filter(module => module.type !== this.$config.project.moduleTypes.moodBoard);
      } else {
        this.modulesDictionary = [...this.defaultModulesDictionary];
      }
    },
    cloneDictionaryModule(module) {
      return {
        ...module,
        id: Date.now(),
        module_id: null,
        original_module_id: module.id,
        newTitle: module.title,
        sort_order: 0,
      };
    },
    moduleToProcess(targetModule) {
      if (targetModule.type === this.$config.project.moduleTypes.moodBoard) {
        this.modulesDictionary = this.modulesDictionary.filter(module => module.type !== this.$config.project.moduleTypes.moodBoard);
      }

      this.scheme.main.push(this.cloneDictionaryModule(targetModule, true));
    },
    dropModuleToProcess(data, type) {
      if (this.scheme[type].find(module => module.type === this.$config.project.moduleTypes.moodBoard)) {
        this.modulesDictionary = this.modulesDictionary.filter(module => module.type !== this.$config.project.moduleTypes.moodBoard);
      }
    },
    moduleToDictionary(targetModule, idx, target) {
      this.scheme[target] = this.scheme[target].filter(module => module.id !== targetModule.id);

      if (targetModule.type === this.$config.project.moduleTypes.moodBoard) {
        this.modulesDictionary.push({
          ...targetModule,
          id: targetModule.module_id,
          ttl: 1,
          showRenameInput: false,
        });
      }
    },
    dropModuleToDictionary() {
      const uniqueModules = this.modulesDictionary.reduce((acc, module) => {
        acc[module.slug] = this.defaultModulesDictionary.find(m => m.slug === module.slug);
        return acc;
      }, {});
      this.modulesDictionary = Object.values(uniqueModules);
    },
    getFormattedScheme() {
      return {
        settings: this.scheme.settings,
        modules: [
          ...this.scheme.main.map((module, idx) => ({
            ...(module.module_id ? {id: module.id} : {}),
            approve: module.approve,
            module_id: module.original_module_id,
            title: module.title,
            icon: module.icon,
            sort_order: idx + 1,
            ttl: this.formatTtl(module.ttl),
            type: this.$config.project.workflowTypes.main,
            workflow_scheme_module_id: module.workflow_scheme_module_id
          })),
          ...this.scheme.additional.map((module, idx) => ({
            ...(module.module_id ? {id: module.id} : {}),
            approve: module.approve,
            module_id: module.original_module_id,
            title: module.title,
            icon: module.icon,
            sort_order: idx + 1,
            ttl: this.formatTtl(module.ttl),
            type: this.$config.project.workflowTypes.additional,
            workflow_scheme_module_id: module.workflow_scheme_module_id
          }))
        ]
      };
    },
    async updateScheme() {
      try {
        const scheme = {...this.getFormattedScheme()};
        const workflowSchemeResponse = await this.$api.project.updateProjectWorkflowScheme(this.$route.params.project_id, {...scheme});
        const projectResponse = await this.$api.project.get(this.$route.params.project_id)
        await this.$store.dispatch('setProject', projectResponse.data);
        this.setWorkflowScheme(workflowSchemeResponse.data);
        this.setShowAdditionalWorkflowValue();
      } catch (error) {
        console.error(error);
      }
    },
    async saveAndClose() {
      if (!this.scheme.main.length) {
        this.$toast.open({
          message: `Select modules!`,
          type: 'error',
          position: 'top-right'
        });
        return
      }
      if (this.scheme.main.every(el => el.slug === this.$config.project.modules.moodBoard)) {
        this.$toast.open({
          message: `White board can't be single module`,
          type: 'error',
          position: 'top-right'
        });
        return
      }

      this.loading = true;

      await this.updateScheme();
      this.setShowAdditionalWorkflowValue();

      this.$toast.open({
        message: `Flow updated!`,
        type: 'success',
        position: 'top-right'
      });

      this.loading = false;
    },
    formatTtl(ttl) {
      return parseInt(ttl.toString().replace(' Days', ''), 10)
    },
    onShowAdditionalWorkflowChange(val) {
      if(!val) {
        const moodBoardModule = this.scheme.additional.find(module => module.type === this.$config.project.moduleTypes.moodBoard)
        if (moodBoardModule) {
          this.modulesDictionary.push({
            ...moodBoardModule,
            id: moodBoardModule.module_id,
            ttl: 1,
            showRenameInput: false,
          });
        }
        this.scheme.additional = [];
      }
    },
    setShowAdditionalWorkflowValue() {
      this.scheme.additional.length ? this.showAdditionalWorkflow = true : this.showAdditionalWorkflow = false
    },
    onMove(val) {
      const listTypes = [this.$config.project.workflowTypesSlugs.main, this.$config.project.workflowTypesSlugs.additional]
      const listType = val.to.dataset.list
      if(listTypes.includes(listType) && this.scheme?.[listType]?.length >= this.maxSchemeModules) {
        return false;
      }
    },
    onModuleChange(data) {
      this.scheme[data.moduleTypeSlug][data.moduleIdx].title = data.title
      this.scheme[data.moduleTypeSlug][data.moduleIdx].approve = data.approve
      this.scheme[data.moduleTypeSlug][data.moduleIdx].ttl = data.ttl
    }
  }
}
</script>

<style lang="scss">
.workflow-tables-wrap {
  .grid-item {
    display: grid !important;
    grid-template-columns: auto minmax(0, 1fr) auto auto auto;
  }

  .v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
  }

  .v-input--selection-controls__input {
    margin-right: 4px;
  }

  .workflow-tables-col {
    min-height: 296px;

    &:first-child {
      max-width: 330px;
    }
  }

  .workflow-table {
    border: 1px solid var(--v-gray-30-base);
    background-color: var(--v-gray-10-base);

    table {
      border-collapse: collapse;
    }

    .workflow-table-head {
      margin-bottom: 6px;
    }

    .workflow-table-row-item {
      margin-bottom: 6px;
    }

    .workflow-item-index {
      min-width: 44px;
    }

    .workflow-item-move-back {
      width: 44px;
    }

    .workflow-ttl-input {
      input {
        height: 28px;
        padding: 9px;
        width: calc(36px + 8px * var(--value-length, 1));
        border: 1px solid var(--v-gray-30-base);
        outline: none;
        border-radius: 4px;
        font-size: 12px;
        color: var(--v-gray-80-base);
        background: var(--v-gray-10-base);
      }

      button {
        top: 4px;
        right: 2px;

        &:last-child {
          top: 14px;
        }
      }
    }

    .bordered-item {
      height: 44px;
      border: 1px solid var(--v-gray-30-base);
      background-color: var(--v-gray-0-base);
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);

      &.empty-state {
        height: 40px;
        border: 1px dashed var(--v-accent-30-base);
      }
    }
  }

  .rename-input-error {
    font-size: 10px !important;
    white-space: break-spaces;
  }
}

.closed-workflow-tooltip {
  padding: 14px 16px 15px;
  font-size: 10px;
  line-height: 12px;
  box-shadow: 0 0 0 1px var(--v-gray-0-base), 0 10px 20px rgba(0, 0, 0, 0.05);

  &::before {
    content: '';
    position: absolute;
    bottom: -11px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-top: 11px solid var(--v-gray-0-base);
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid var(--v-gray-80-base);
  }
}
</style>

<style lang="scss" scoped>
.additional-drag {
  min-height: 70px;
}
.approved-col {
  min-width: 117px;
}
.ttl-col {
  min-width: 83px;
}
.first-col {
  min-width: 39px;
}
.header-col-1 {
  margin-left: 55px;
  flex-shrink: 0;
}
.header-col-2 {
  min-width: 117px;
}
.header-col-3 {
  min-width: 83px;
}
.header-col-4 {
  min-width: 60px;
}
</style>
